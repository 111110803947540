import request from "@/Rest";
import { TRIAL_URLS, ANALYTICS_URLS } from "@/Rest/api/urls";

export async function listTrials() {
  try {
    const { data } = await request.GET(TRIAL_URLS.getTrials);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function totalTrialAverage() {
  try {
    const { data } = await request.GET(ANALYTICS_URLS.getTotalTrialsMinutes);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getTotalModes() {
  try {
    const { data } = await request.GET(ANALYTICS_URLS.getTotalModes);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}
