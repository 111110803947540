<template>
  <transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div v-if="hasFooter" class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { computed } from '@vue/runtime-core';
export default {
  name: 'Modal',
  props: ['show'],
  setup(_, ctx) {
    const hasFooter = computed(() => !!ctx.slots.footer);

    return {
      hasFooter
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close{
  text-align: right;
}
.modal-container {
  overflow-x: scroll;
  min-width: 600px;
  max-width: 50%;
  max-height: 60%;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  display: table;
}

.modal-header h3 {
  margin-top: 0;
}

.modal {
  &-header, &-body, &-footer {
    padding: 15px;
  }
}
</style>
