import request from "@/Rest";
import { ANALYTICS_URLS } from "@/Rest/api/urls";

export async function totalSessions() {
  try {
    const { data } = await request.GET(ANALYTICS_URLS.getTotalSessions);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}
