import request from "@/Rest";
import { OCULUS_URLS, ANALYTICS_URLS } from "@/Rest/api/urls";

export async function oculusLogin() {
  try {
    const { data } = await request.GET(ANALYTICS_URLS.getTotalTrials);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function oculusRelease() {
  try {
    const { data } = await request.GET(OCULUS_URLS.oculusRelease);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function oculusManual() {
  try {
    const { data } = await request.GET(OCULUS_URLS.oculusManual);

    return { data: data[0], success: true };
  } catch (error) {
    return { success: false };
  }
}
