<template>
  <div class="w-full max-h-screen">
    <div v-if="showModal">
      <Modal
        v-for="message in modalMessages"
        :key="message.id"
        :id="message.id"
        @close="closeModal"
      >
        <template v-slot:header>{{ message.message }} </template>

        <template v-slot:body> {{ message.description }} </template>
      </Modal>
    </div>
    <div v-if="user.type === 'Internal'">
      <Dashboard />
    </div>
    <div v-else class="w-full p-5">
      <Welcome />
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import Modal from "@/components/general/Modal.vue";
import { getListMessages } from "@/services/user/user.js";
import { useToast } from "vue-toastification";
import { useCookies } from "vue3-cookies";
import Dashboard from "@/views/Dashboard.vue";
import Welcome from "@/views/Welcome.vue";
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";

const toast = useToast();
const { cookies } = useCookies();
const route = useRoute();
const store = useStore();

const user = computed(() => store.getters["user/getUserData"]);

const messages = ref([]);
const showModal = ref(true);
const modalMessages = ref([]);
const cookiesMessages = ref([]);

onMounted(() => {
  if (user.value.type !== "Internal") {
    if (route.query.messages) {
      getMessages();
    }
  }
});

function getMessages() {
  getListMessages().then((response) => {
    messages.value = response.data;
    filterMessages();
  });
}

function filterMessages() {
  verifyCookieExists();
  for (const message of messages.value) {
    if (message["concealable"] && !verifyMessageExists(message.id)) {
      modalMessages.value.push(message);
    }
  }
  if (modalMessages.value.length === 0) {
    showModal.value = false;
    showPopUpMessages();
  }
}

function showPopUpMessages() {
  for (const message of messages.value) {
    if (!message["concealable"]) {
      toast.info(message["message"], { timeout: false });
    }
  }
}

function closeModal(data) {
  if (data[1]) {
    cookiesMessages.value.push(data[0]);
  }
  modalMessages.value.pop();
  if (modalMessages.value.length === 0) {
    cookies.set("messages", JSON.stringify(cookiesMessages));
    showPopUpMessages();
  }
}

function verifyCookieExists() {
  cookiesMessages.value = JSON.parse(cookies.get("messages")) ?? [];
}

function verifyMessageExists(messageId) {
  return cookiesMessages.value.includes(messageId);
}
</script>
