<template>
  <h1 class="m-4 text-white text-xl font-semibold">Dashboard</h1>

  <div
    class="flex text-white justify-center mx-auto mt-5 w-11/12 md:w-full gap-4"
  >
    <InfoCard
      iconAlt="Total Users"
      title="Total Users"
      :value="totalUsersNumber.toString()"
    />
    <InfoCard
      iconAlt="Available Seats"
      title="Pre-Purchased Seats Available"
      :value="seatAvaliable.toString()"
    />
    <InfoCard
      iconAlt="Total Delegates"
      title="Total Delegates"
      :value="totalDelegateNumber.toString()"
    />
  </div>

  <div
    class="flex text-white justify-center mx-auto mt-4 w-11/12 md:w-full gap-4"
  >
    <InfoCard
      iconAlt="Total Training Sessions"
      title="Total Training Sessions Completed"
      subtitle="(Prior Day)"
      :value="totalTrainingPriorDay.toString()"
    />
    <InfoCard
      iconAlt="Total Training Sessions"
      title="Total Training Sessions Completed"
      subtitle="(Past 7 days)"
      :value="totalTrainingSevenDay.toString()"
    />
    <InfoCard
      iconAlt="Users Who Complete Training Sessions"
      title="Users Who Have Completed a Training Session"
      subtitle="(Past 7 days)"
      :value="totalUsersPerTraining.toString()"
    />
  </div>

  <div class="mx-4 mt-10 mb-6 pb-6">
    <div class="flex justify-between mb-2">
      <div></div>
      <div>
        <button
          class="block margin-3 mb-3 md:inline md:ml-2 text-center px-4 py-2 font-semibold bg-mindaro text-darkBlue"
          @click="downloadUserReport"
        >
          <em v-if="isDownloadingReport" class="fa fa-spinner fa-spin"></em>
          Run Report
        </button>
      </div>
    </div>
    <LocationsTable
      :data="usersData"
      :labels="[
        { text: 'Name', value: 'full_name' },
        { text: 'Active', value: 'is_active' },
        { text: 'Enrollment date', value: 'created_at' },
        { text: 'Current session', value: 'current_session_title' },
        { text: 'Total sessions completed', value: 'completed_sessions' },
        {
          text: 'Date last session completed',
          value: 'last_session_completed_date',
        },
        {
          text: 'Sessions completed in the past 7 days',
          value: 'session_completed_last_seven_days',
        },
        {
          text: 'Sessions completed in the past 30 days',
          value: 'session_completed_last_thirty_days',
        },
        { text: 'Completed assessments', value: 'completed_assessments' },
      ]"
      :checkboxes="true"
      @selectionChanged="handleSelectionChanged"
      @changePage="getAnalyticsData"
      :isOrderedColumns="true"
      :order-fields="orderFields"
      @toggle-order="toggleOrder"
    >
      <template
        #table-content="{ selectedItems, toggleSelection }"
        v-if="usersData"
      >
        <tr
          v-for="userData in usersData.results"
          :key="userData"
          class="bg-white p-5"
        >
          <td class="text-center">
            <input
              type="checkbox"
              :checked="selectedItems.includes(userData.id)"
              @change="() => toggleSelection(userData.id)"
            />
          </td>
          <td class="text-center">
            {{ userData.full_name }}
          </td>
          <td class="text-center">
            <input type="checkbox" :checked="userData.is_active" disabled />
          </td>
          <td class="text-center">
            {{ formatDate(userData.created_at) }}
          </td>
          <td class="text-center">
            {{
              userData.current_session_title
                ? userData.current_session_title
                : "-"
            }}
          </td>
          <td class="text-center">
            {{ userData.completed_sessions }}
          </td>
          <td class="text-center">
            {{
              userData.last_session_completed_date
                ? formatDate(userData.last_session_completed_date)
                : "-"
            }}
          </td>
          <td class="text-center">
            {{ userData.session_completed_last_seven_days }}
          </td>
          <td class="text-center">
            {{ userData.session_completed_last_thirty_days }}
          </td>
          <td class="text-center">
            <ul style="list-style-type: none; padding: 0; margin: 0">
              <li
                v-for="assessment in userData.completed_assessments"
                :key="assessment.id"
                style="margin-bottom: 10px"
              >
                <ul style="list-style-type: none; padding-left: 15px">
                  <li v-if="assessment.url">
                    <a
                      :href="assessment.url"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="text-blue-500 underline"
                      ><span
                        class="inline-block w-2 h-2 bg-blue-500 rounded-full mr-2"
                      ></span>
                      <strong>{{ assessment.assessment }}</strong></a
                    >
                  </li>
                  <li v-if="assessment.date_completed">
                    {{ formatDate(assessment.date_completed) }}
                  </li>
                </ul>
              </li>
            </ul>
          </td>
        </tr>
      </template>
    </LocationsTable>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import LocationsTable from "@/components/location/LocationsTable.vue";

import InfoCard from "@/components/general/InfoCard.vue";
import {
  getTotalUsers,
  getTotalSeats,
  getTotalDelegates,
  getTotalTrainigSessions,
  getTotalUsersPerSessions,
  getAnalyticsUserData,
  getAnalyticsUserDataReport,
} from "@/services/location/location.js";
import { useStore } from "vuex";

const store = useStore();

const user = computed(() => store.getters["user/getUserData"]);

const totalUsersNumber = ref(0);
const totalDelegateNumber = ref(0);
const seatAvaliable = ref(0);
const totalTrainingPriorDay = ref(0);
const totalTrainingSevenDay = ref(0);
const totalUsersPerTraining = ref(0);
const usersData = ref([]);
const recordsIds = ref([]);
const isDownloadingReport = ref(false);
const orderFields = ref(['full_name']);

onMounted(async () => {
  await totalUsers();
  await totalSeats();
  await totalDelegates();
  await getTotalTrainingPriorDay();
  await getTotalTrainingSevenDay();
  await getTotalUsersPerSessionsSevenDay();
  await getAnalyticsData();
});

async function getAnalyticsData(page) {
  const orderQuery = orderFields.value;
  const response = await getAnalyticsUserData(
    user.value.location,
    orderQuery,
    page
  );
  usersData.value = response;
}

async function totalUsers() {
  const response = await getTotalUsers(user.value.location);
  totalUsersNumber.value = response.data.total_users;
}

async function totalSeats() {
  const response = await getTotalSeats(user.value.location);
  seatAvaliable.value = response.data.total_seats;
}

async function totalDelegates() {
  const response = await getTotalDelegates(user.value.location);
  totalDelegateNumber.value = response.data.total_delegates;
}

async function getTotalTrainingPriorDay() {
  const response = await getTotalTrainigSessions({
    location: user.value.location,
    days: 1,
  });

  totalTrainingPriorDay.value = response.total_sessions;
}

async function getTotalTrainingSevenDay() {
  const response = await getTotalTrainigSessions({
    location: user.value.location,
    days: 7,
  });
  totalTrainingSevenDay.value = response.total_sessions;
}

async function getTotalUsersPerSessionsSevenDay() {
  const response = await getTotalUsersPerSessions({
    location: user.value.location,
    days: 7,
  });

  totalUsersPerTraining.value = response.total_users_per_session;
}

function formatDate(date) {
  const dateAdded = new Date(date);
  return `${
    dateAdded.getMonth() + 1
  }/${dateAdded.getDate()}/${dateAdded.getFullYear()}`;
}

function handleSelectionChanged(selectedItems) {
  recordsIds.value = selectedItems;
}

async function downloadUserReport() {
  isDownloadingReport.value = true;
  const orderQuery = orderFields.value;
  const response = await getAnalyticsUserDataReport(
    recordsIds.value,
    orderQuery
  );

  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "User report.xlsx");
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
  isDownloadingReport.value = false;
}

function toggleOrder(field, currentPage) {
  const existingIndex = orderFields.value.findIndex(
    (orderField) => orderField.replace("-", "") === field
  );
  if (existingIndex >= 0) {
    const isDescending = orderFields.value[existingIndex].startsWith("-");
    if (isDescending) {
      orderFields.value.splice(existingIndex, 1);
    } else {
      orderFields.value[existingIndex] = `-${field}`;
    }
  } else {
    orderFields.value.push(field);
  }

  getAnalyticsData(currentPage);
}
</script>

<style scoped>
img {
  border-radius: 50%;
  background-color: #edfe86;
  width: 52px;
  height: 52px;
}

#card {
  width: 30%;
}
table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}
</style>
