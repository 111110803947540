<template>
  <div class="flex text-white justify-around mx-auto mt-5 w-11/12 md:w-full">
    <div
      id="card"
      class="flex flex-col md:flex-row justify-center items-center p-2 bg-darkBlue"
    >
      <img
        class="p-2 ml-3"
        src="@/assets/icons/location-delegate-icon.svg"
        width="50"
        alt=""
      />
      <div class="ml-4 pt-3">
        <span>Total Users</span>
        <p>{{ totalUsersNumber }}</p>
      </div>
    </div>
    <div
      id="card"
      class="flex flex-col md:flex-row justify-center items-center bg-darkBlue p-2"
    >
      <img
        class="p-2 ml-3"
        src="@/assets/icons/location-delegate-icon.svg"
        width="52"
        alt=""
      />
      <div class="ml-4 pt-3">
        <span>Total Admins</span>
        <p>{{ totalAdmins }}</p>
      </div>
    </div>
    <div
      id="card"
      class="flex flex-col md:flex-row justify-center items-center bg-darkBlue p-2"
    >
      <img
        class="p-2 ml-3"
        src="@/assets/icons/location-delegate-icon.svg"
        width="52"
        alt=""
      />
      <div class="ml-4 pt-3">
        <span>Total Locations</span>
        <p>{{ totalLocationsNumber }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  getTotalLocationAdmins,
  locations,
} from "@/services/location/location.js";
import { totalUsers } from "@/services/user/user.js";

const totalUsersNumber = ref(0);
const totalLocationsNumber = ref(0);
const totalAdmins = ref(0);

async function getTotalUsers() {
  const response = await totalUsers();
  totalUsersNumber.value = response.data.total_users;
}

async function totalLocationAdmins() {
  const response = await getTotalLocationAdmins();
  console.log(response.data);
  totalAdmins.value = response.data.total_location_admins;
}

async function totalLocations() {
  const response = await locations();
  totalLocationsNumber.value = response.data.locations.length;
}

onMounted(() => {
  getTotalUsers();
  totalLocationAdmins();
  totalLocations();
});
</script>

<style scoped>
img {
  border-radius: 50%;
  background-color: #edfe86;
  width: 52px;
  height: 52px;
}

#card {
  width: 30%;
}
</style>
