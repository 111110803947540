<template>
  <div
    class="lg:flex lg:justify-between bg-white m-2 lg:m-2 p-3 h-screen overflow-auto"
  >
    <div class="w-full px-2 lg:px-8 lg:w-1/2 h-full">
      <h1 class="font-semibold text-xl">Welcome to your Mastermind portal.</h1>
      <h2 class="text-sm">
        Please ensure you have taken the following steps to get started:
      </h2>
      <div class="bg-brightGray mt-8 p-5 text-darkBlue font-medium">
        <div>
          <div class="mt-12 flex items-center">
            <img src="@/assets/icons/review-icon.png" alt="menu" width="40" />
            <p class="ml-3">
              Review the
              <a
                href="https://www.mastermindsports.com/quick-start-guide"
                target="_blank"
                class="underline bg-[#0065FF]"
                >Quick Start Guide</a
              >
              <em
                class="fa-solid fa-arrow-up-right-from-square ml-1 text-xs"
              ></em
              >.
            </p>
          </div>
          <div class="mt-12 flex items-center">
            <img src="@/assets/icons/download-icon.svg" alt="menu" width="40" />
            <p class="ml-2">
              Download your Mastermind Sports application through the Meta
              store.
            </p>
          </div>
          <div class="mt-12 flex items-center">
            <img
              src="@/assets/icons/credential-icon.png"
              alt="menu"
              width="40"
            />
            <p class="ml-2 text-base">
              Use the same credentials you ceated for this portal to log in to
              the Oculus application.
            </p>
          </div>
          <div class="my-12 flex items-center">
            <img src="@/assets/icons/training-icon.png" alt="menu" width="40" />
            <p class="ml-2">Start training!</p>
          </div>
        </div>
      </div>
      <p class="mt-5 text-sm text-center">
        You can find more helpful information using the links in the left
        navigation pane. If you have questions, you can reach us at<a
          class="underline"
          href="mailto: support@mastermindsports.com"
        >
          support@mastermindsports.com</a
        >
        <em class="fa-solid fa-arrow-up-right-from-square ml-1 text-xs"></em>
      </p>
    </div>
    <div class="hidden lg:block lg:w-1/2 h-full">
      <img
        class="h-full"
        src="@/assets/welcome-image.png"
        alt=""
        width="1900px"
      />
    </div>
  </div>
</template>
