<template>
  <div class="container p-4">
    <h5 class="text-center">Number of sessionss done</h5>
    <canvas :id="this.id"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "Chart",
  props: {
    labels: Array,
    labels1: Array,
    data: Array,
    data1: Array,
    id: String,
  },
  mounted() {
    console.log(this.data);
    const ctx = document.getElementById(this.id);
    this.myChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "Oculus",
            data: this.data,
            backgroundColor: "rgba(54,73,93,.5)",
            borderColor: "#36495d",
            borderWidth: 3,
            yAxisID: "y",
          },
          {
            label: "Mobile",
            data: this.data1,
            backgroundColor: "rgba(255, 0, 0)",
            borderColor: "#FF0000",
            borderWidth: 2,
            yAxisID: "y",
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          y: {
            ticks: {
              precision: 0,
            },
            beginAtZero: true,
          },
        },
      },
    });
  },
};
</script>
